<template>
    <div class="search-page">
        <div class="search-page-header">
            <div class="search-page-header-title">Search</div>
            <div class="search-page-header-input">
                <el-input 
                    v-model="value" 
                    size="small" 
                    clearable 
                    prefix-icon="el-icon-search"
                    @input="get_list"
                    @clear="clear_value" />
            </div>
        </div>

        <div class="search-page-body">
            <div class="search-page-sidebar">
                <div class="sp-sidebar-content">
                    <div class="sp-sidebar-content-title">Search in</div>
                    <el-checkbox-group v-model="search_types" class="sp-filter-checkbox_group" @change="get_list">
                        <el-checkbox label="client">Clients</el-checkbox>
                        <el-checkbox label="instrument">Instruments</el-checkbox>
                        <el-checkbox label="portfolio">Portfolios</el-checkbox>
                        <el-checkbox label="strategy">Strategies</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="spacer"></div>
                <div class="sp-sidebar-button">
                    <el-button type="danger" plain @click="clear_filter">
                        <svg-icon icon-class="delete"></svg-icon>
                        Clear
                    </el-button>
                </div>
            </div>

            <div class="search-page-content">
                <vxe-table
                    :data="search_result"
                    size="mini"
                    @cell-click="open_item"
                    :scroll-y="{oSize: 10, gt: 50}"
                    class="common-grid">
                    <vxe-table-column
                        field="name"
                        type="html"
                        class-name="search-page-row"
                        header-class-name="search-page-column"
                        title="Name">
                        <template #default="{row}">
                            <div v-html="highlight(row.name)"></div>
                            <div>
                                {{row.pagetype}}
                                <span v-if="row.datatype === 'instrument'">
                                    - {{row.asset}}
                                </span>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
        </div>
    </div>
</template>

<script>
import Urls from '@/../config/url'

export default {
    data(){
        return {
            value : undefined,
            search_types: ['instrument','client','portfolio','strategy'],
            search_result: [],
        }
    },

    computed: {
        global_search: {
            get() { return this.$store.state.app.global_search; },
            set(value) { this.$store.commit('app/set', {type: 'global_search', items:value}); },
        },

        global_search_type: {
            get() { return this.$store.state.app.global_search_type; },
            set(value) { this.$store.commit('app/set', {type: 'global_search_type', items:value}); },
        },
    },

    methods: {
        open_item({row}){
            switch(row.datatype){
                case 'client':
                    this.$router.push({name: 'client', params: {clientid: row.id}})
                    break;
                case 'portfolio':
                    this.$router.push({name: 'client_portfolio', params: {clientid: row.parent_id, portfolioid: row.id}})
                    break
                case 'instrument':
                    let route_page_key = 'isin'

                    let route_names = {
                        bond: 'instruments_bond_isin',
                        equity: 'instruments_equity_isin',
                        option: 'instruments_options_isin',
                        future: 'instruments_futures_isin',
                        currency: 'instruments_currency_isin',

                    }

                    if(route_names[row.assettype])
                        this.$router.push({
                            name: route_names[row.assettype],
                            params: {
                                [route_page_key]: row.id
                            }
                        })

                    break
                case 'strategy':
                    break
                default:
                    break
            }
        },
        set_values(){
            if (this.global_search)
                this.value = this.global_search;
            else
                this.value = '';

            if (this.global_search_type) {
                if (this.global_search_type === 'instrument')
                    this.search_types = ['instrument'];
                else if (this.global_search_type === 'client')
                    this.search_types = ['client'];
                else
                    this.search_types = ['portfolio','strategy'];
            }
            else {
                this.search_types = ['instrument','client','portfolio','strategy'];
            }

            if (this.value)
                this.get_list();
        },

        clear_filter(){
            this.value = '';
            this.search_types = ['instrument','client','portfolio','strategy'];
            this.search_result = []
        },

        clear_value(){
            this.value = '';
            this.search_result = []
        },
        clear_results(){
            this.search_result = []
        },

        async get_list(){
            this.search_result = []

            if(this.value.length < 2) return

            if(this.value){
                let results = await this.axios.get(Urls.searchGlobalLong, {
                    params: {
                        term: this.value,
                        where: this.search_types
                    }
                })
                .then((response) => {
                    return response.data._items
                })
                if(results && this.value){
                    this.search_result = []
                    results.forEach((item) => {
                        if(item.name && this.value) this.search_result.push(item)
                    })
                }
            } else {
                this.clear_results()
            }
        },
        highlight(str){
            let value = String(this.value)
            const n = str.toUpperCase();
            const q = value.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return str;
            }
            const l = q.length;
            return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
        },
    },

    watch: {
        value(val){
            this.global_search = val;
        },
    },

    mounted(){
        this.set_values();
    }
}
</script>
